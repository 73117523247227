import { render, staticRenderFns } from "./ProductSummary.vue?vue&type=template&id=3257976a"
import script from "./ProductSummary.vue?vue&type=script&lang=js"
export * from "./ProductSummary.vue?vue&type=script&lang=js"
import style0 from "./ProductSummary.vue?vue&type=style&index=0&id=3257976a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports