import Setting from '../pages/Setting/index.vue'
import Branch from '../pages/Setting/Branch.vue'
import Role from '../pages/Setting/Role.vue'
import Zone from '../pages/Setting/Zone.vue'
import Hotel from '../pages/Setting/Hotel.vue'
import Insurance from '../pages/Setting/Insurance.vue'
import AssistInsurance from '../pages/Setting/AssistInsurance.vue'
import Doctor from '../pages/Setting/Doctor.vue'
import InsuranceBroker from '../pages/Setting/InsuranceBroker.vue'
import Nurse from '../pages/Setting/Nurse.vue'
import Paramedic from '../pages/Setting/Paramedic.vue'
import PublicHealth from '../pages/Setting/PublicHealth.vue'
export default [
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    redirect: { name: "Setting Branch" },
    children: [
      // {
      //   path: "",
      //   name: "Setting Role",
      //   component: Role
      // },
      {
        path: "/branch",
        name: "Setting Branch",
        component: Branch
      },
      {
        path: "/setting-insurance",
        name: "Setting Insurance",
        component: Insurance
      },
      {
        path: "/setting-assistinsurance",
        name: "Setting Assist Insurance",
        component: AssistInsurance
      },
      {
        path: "/zone",
        name: "Setting Zone",
        component: Zone
      },
      {
        path: "/hotel-list",
        name: "Setting Hotel",
        component: Hotel
      },
      {
        path: "/doctor-list",
        name: "Doctor list",
        component: Doctor
      },
      {
        path: "/insuranceBroker-list",
        name: "Insurance Broker",
        component: InsuranceBroker
      },
      {
        path: "/nurse-list",
        name: "Nurse list",
        component: Nurse
      },
      {
        path: "/paramedic-list",
        name: "Paramedic list",
        component: Paramedic
      },
      {
        path: "/publicHealth-list",
        name: "Public Health list",
        component: PublicHealth
      },
    ]
  }
];
