import PatientSummary from "../pages/Patient/PatientSummary";
import PatientSummaryHome from "../pages/Patient/PatientSummaryHome";
import PatientProfile from "../pages/Patient/Profile";
import PatientEdit from "../pages/Patient/Edit";
import PatientGeneralInfo from "../pages/Patient/GeneralInfo";
import PatientFullDetails from "../pages/Patient/GeneralInfo/FullDetails";
import PatientPrintForm from "../pages/Patient/GeneralInfo/PrintForm";
import PatientSticker from "../pages/Patient/GeneralInfo/Sticker";
import PatientVisitSummary from "../pages/Patient/VisitSummary";
import PatientBillingSummary from "../pages/Patient/Billing/BillingSummary";
import PatientVisitInformation from "../pages/Patient/Visit/Information";
import PatientVisitNurseAssessment from "../pages/Patient/Visit/NurseAssessment";
import GeneralMedicalCertificate from "../pages/Patient/Visit/MedicalCertificate/General";
import FitToFlyCertificate from "../pages/Patient/Visit/MedicalCertificate/FitToFly";
import WorkPermitCertificate from "../pages/Patient/Visit/MedicalCertificate/WorkPermit";
import InsuranceCertificate from "../pages/Patient/Visit/MedicalCertificate/Insurance";
import ScubaPADICertificate from "../pages/Patient/Visit/MedicalCertificate/ScubaPADI";
import ScubaCertificate from "../pages/Patient/Visit/MedicalCertificate/Scuba";
import PatientVisitOpdCard from "../pages/Patient/Visit/OpdCard";
import PatientVisitInvestigationResults from "../pages/Patient/Visit/InvestigationResults";
import PatientVisitDoctorOrder from "../pages/Patient/Visit/DoctorOrder";
import PatientVisitObservation from "../pages/Patient/Visit/Observation";
import PatientVisitMedicine from "../pages/Patient/Visit/Medicine";
import PatientVisitAdmission from "../pages/Patient/Visit/Admission";
import PatientVisitDischargeStatus from "../pages/Patient/Visit/DischargeStatus";
import PatientVisitTransfer from "../pages/Patient/Visit/Transfer";
import PatientVisitBilling from "../pages/Patient/Visit/Billing";
import PatientVisitOthers from "../pages/Patient/Visit/Others";
import PatientInvestigationSummary from "../pages/Patient/InvestigationSummary";
import PatientAdmissionSummary from "../pages/Patient/AdmissionSummary";
import PatientOperationSummary from "../pages/Patient/OperationSummary";
import PatientAppointments from "../pages/Patient/Appointments";
import PatientAppointmentsUpComing from "../pages/Patient/Appointments/UpComingAppointment";
import PatientAppointmentsPassed from "../pages/Patient/Appointments/PassedAppointment";
import PatientMedicines from "../pages/Patient/Medicines";
import PatientDocuments from "../pages/Patient/Documents";
import PatientOthers from "../pages/Patient/Others";
import InvestSumListOfAllTests from "../pages/Patient/InvestigationSummary/ListOfAllTests";
import InvestSumRadiology from "../pages/Patient/InvestigationSummary/RadiologyAndImaging";
import InvestSumLaboratory from "../pages/Patient/InvestigationSummary/Laboratory";
import InvestSumListOthers from "../pages/Patient/InvestigationSummary/Others";

import PatientAdmissionOPDDoc from '../pages/Patient/Admission/OPDDoc'
import PatientAdmissionIPDDoc from '../pages/Patient/Admission/IPDDoc'
import PatientAdmissionForm from '../pages/Patient/Admission/AdmissionForm'
import PatientConsentForm from '../pages/Patient/Admission/ConsentForm'

import PatientProgressNoteList from '../pages/Patient/Admission/ProgressNoteList'
import PatientPhysicianOrder from '../pages/Patient/Admission/PhysicianOrder'
import PatientViewLab from '../pages/Patient/Admission/ViewLab'
import PatientViewInvestigation from '../pages/Patient/Admission/ViewInvestigation'
import PatientViewHistory from '../pages/Patient/Admission/ViewHistory'

export default [
  {
    path: "/patient",
    name: "Visits Summary",
    component: PatientSummary,
    redirect: { name: "Visit Summary Home" },
    children: [
      {
        path: "",
        name: "Visit Summary Home",
        component: PatientSummaryHome
      },
      {
        path: ":id",
        
        component: PatientProfile,
        children: [
          {
            path: "",
            
            component: PatientGeneralInfo,
            children: [
              {
                
                path: "",
                name: "General Info",
              },
              {
                path: "edit",
                name: "Patient Edit",
                component: PatientEdit
              },
              {
                path: "details",
                name: "Patient Full Details",
                component: PatientFullDetails
              },
              {
                path: "form",
                name: "Patient Print Form",
                component: PatientPrintForm
              },
              {
                path: "sticker",
                name: "Patient Sticker",
                component: PatientSticker
              }
            ]
          },
          {
            path: "visit",
            name: "Visit Summary",
            component: PatientVisitSummary
          },
          {
            path: "billing",
            name: "Patient Billing Summary",
            component: PatientBillingSummary
          },
          {
            path: ":visitId",
            name: "Visit Information",
            component: PatientVisitInformation,
            children: [
              {
                path: "nurse-assessment",
                name: "Nurse Assessment",
                component: PatientVisitNurseAssessment,
                meta: {
                  nursing_sheet: true,
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "opd-card",
                name: "OPD Card",
                component: PatientVisitOpdCard,
                meta: {
                  doctor_sheet: true,
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "investigation-results",
                name: "Investigation Results",
                component: PatientVisitInvestigationResults,
                meta: {
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "doctor-order",
                name: "Doctor Order",
                component: PatientVisitDoctorOrder,
                meta: {
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "observation",
                name: "Patient Visit Observation",
                component: PatientVisitObservation,
                meta: {
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "medicine",
                name: "Medicine",
                component: PatientVisitMedicine,
                meta: {
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "admission",
                name: "Patient Visit Admission",
                component: PatientVisitAdmission,
                meta: {
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "general-cert",
                name: "General Medical Certificate",
                component: GeneralMedicalCertificate,
                meta: {
                  medcert: true,
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "fit-to-fly-cert",
                name: "Fit to fly Certificate",
                component: FitToFlyCertificate,
                meta: {
                  medcert: true,
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "work-permit-cert",
                name: "Medical Certificate for Work Permit",
                component: WorkPermitCertificate,
                meta: {
                  medcert: true,
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "insurance-cert",
                name: "Medical Certificate for Insurance",
                component: InsuranceCertificate,
                meta: {
                  medcert: true,
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "scuba-padi",
                name: "Medical Certificate for SCUBA PADI",
                component: ScubaPADICertificate,
                meta: {
                  medcert: true,
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "scuba",
                name: "Medical Certificate for SCUBA",
                component: ScubaCertificate,
                meta: {
                  medcert: true,
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "discharge status",
                name: "Discharge Status",
                component: PatientVisitDischargeStatus,
                meta: {
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "transfer",
                name: "Transfer",
                component: PatientVisitTransfer,
                meta: {
                  visit_active: true,
                  is_opd_docs: true
                }
              },
              {
                path: "billing",
                name: "Patient Visit Billing",
                component: PatientVisitBilling,
                meta: {
                  visit_active: true
                }
              },
              {
                path: "others",
                name: "Patient Visit Others",
                component: PatientVisitOthers,
                meta: {
                  visit_active: true,
                  is_opd_docs: true
                }
              }
            ]
          },
          {
            path: "investigation",
            name: "Investigation Summary",
            component: PatientInvestigationSummary,
            children: [
              {
                path: "list-of-all-tests",
                name: "List of all tests",
                component: InvestSumListOfAllTests,
                meta: {
                  invest_active: true
                }
              },
              {
                path: "radiology",
                name: "Radiology and Imaging",
                component: InvestSumRadiology,
                meta: {
                  invest_active: true
                }
              },
              {
                path: "laboratory",
                name: "Laboratory",
                component: InvestSumLaboratory,
                meta: {
                  invest_active: true
                }
              },
              {
                path: "invest-others",
                name: "Invest Others",
                component: InvestSumListOthers,
                meta: {
                  invest_active: true
                }
              }
            ]
          },
          {
            path: "admission",
            name: "Admission Summary",
            component: PatientAdmissionSummary,
            children: [
              {
                path: 'opd-docs',
                name: 'Patient Admission OPD doc',
                component: PatientAdmissionOPDDoc
              },
              {
                path: 'IPD-docs',
                name: 'Patient Admission IPD doc',
                component: PatientAdmissionIPDDoc,
                children: [
                  {
                    path: 'admission-form',
                    name: 'Admission Form',
                    component: PatientAdmissionForm
                  },
                  {
                    path: 'admission-form',
                    name: 'Consent Form',
                    component: PatientConsentForm
                  },
                  {
                    path: 'progress-note',
                    name: 'Progress Note List',
                    component: PatientProgressNoteList
                  },
                  {
                    path: 'physician order',
                    name: `Physician's Order Sheet`,
                    component: PatientPhysicianOrder
                  },
                  {
                    path: 'labs',
                    name: 'View Lab',
                    component: PatientViewLab
                  },
                  {
                    path: 'investigations',
                    name: 'View Investigation',
                    component: PatientViewInvestigation
                  },
                  {
                    path: 'history',
                    name: 'View History',
                    component: PatientViewHistory
                  },
                ]
              },
            ]
          },
          {
            path: "operation",
            name: "Operation Summary",
            component: PatientOperationSummary
          },
          {
            path: "documents",
            name: "Documents",
            component: PatientDocuments
          },
          {
            path: "patient-appointments",
            name: "Patient Appointments",
            component: PatientAppointments,
            children: [
              {
                path: "upcoming-appointments",
                name: "Up Coming Appointments",
                component: PatientAppointmentsUpComing,
                meta: {
                  appt_active: true
                }
              },
              {
                path: "patient-appointments",
                name: "Passed Appointments",
                component: PatientAppointmentsPassed,
                meta: {
                  appt_active: true
                }
              }
            ]
          },
          {
            path: "medicines",
            name: "Medicines",
            component: PatientMedicines
          },
          {
            path: "others",
            name: "Others",
            component: PatientOthers
          }
        ]
      }
    ]
  }
];
