<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      :width="size"
      :height="size"
      :fill="color"
    >
      <circle cx="32" cy="32" r="30" stroke="red" stroke-width="4" fill="none" />
      <line x1="15" y1="15" x2="49" y2="49" stroke="red" stroke-width="4" />
    </svg>
  </template>
  
  <script>
  export default {
    props: {
      size: {
        type: [String, Number],
        default: 64, // Default icon size
      },
      color: {
        type: String,
        default: "red", // Default icon color
      },
    },
  };
  </script>