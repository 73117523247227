<template>
  <div class="container-fluid">
    <div class="card shadow mt-2">
      <div class="card-body">
        <h3 class="text-center" :class="$store.state.branch == 3 ? 'text-wa' : 'text-primary'">Patient Summary</h3>
        <div class="d-flex justify-content-center">

          <div class="span3 text-center mx-2">
            <MazSelect v-model="search_date_type" style="z-index: 1" placeholder="select date filter type"
              @change="handleFilterDateSearchType" :options="[
                { label: 'Search by day', value: 'by-day' },
                { label: 'Search by range', value: 'by-range' },
              ]" />
          </div>

          <div v-if="search_date_type === 'by-day'" class="span3 text-center d-flex mx-3">
            <MazPicker v-model="search_date_value" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY"
              placeholder="Period Date" style="z-index: 1 !important;" @input="onDateChange" />
          </div>
          <div v-if="search_date_type === 'by-range'" class="span3 text-center">
            <MazPicker v-model="filterDate"  no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY"
              placeholder="Period Date Range" style="z-index: 1 !important" range @input="onDateChange" />
          </div>

          <!-- <button class="btn btn-primary" @click.prevent="triggerSearchData">
            <h5>Search</h5>
          </button> -->
          <button class="btn btn-primary ml-3" @click.prevent="triggerInitData" v-if="currentMenu !== 'REFERRAL'">
            <h5><i class="fa fa-plus" aria-hidden="true"></i> Add</h5>
          </button>
        </div>

        <div class="mt-4">
          <b-nav class="mb-2" tabs>
            <b-nav-item :active="currentMenu === menu.name" v-for="(menu, index) in menus" :key="index"
              @click="currentMenu = menu.name">{{ menu.name }}</b-nav-item>
          </b-nav>
          <div v-if="currentMenu === 'OPD'">
            <opd-tab ref="opdTab" :dateStart="search_date_type === 'by-day' ? search_date_value : filterDate.start"
              :dateEnd="search_date_type === 'by-day' ? search_date_value : filterDate.end" :branch="branch" />
          </div>
          <div v-if="currentMenu === 'IPD'">
            <ipd-tab ref="ipdTab" :dateStart="search_date_type === 'by-day' ? search_date_value : filterDate.start"
              :dateEnd="search_date_type === 'by-day' ? search_date_value : filterDate.end" :branch="branch" />
          </div>
          <div v-if="currentMenu === 'REFERRAL'">
            <referral-tab ref="referralTab"
              :dateStart="search_date_type === 'by-day' ? search_date_value : filterDate.start"
              :dateEnd="search_date_type === 'by-day' ? search_date_value : filterDate.end" :branch="branch" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";
import opd from './opd.vue'
import ipd from './ipd.vue'
import referral from './referral.vue'

export default {
  components: {
    "opd-tab": opd,
    "ipd-tab": ipd,
    "referral-tab": referral,
  },
  data() {
    return {
      currentMenu: "OPD",
      search_date_type: 'by-day',
      search_date_value: moment(new Date(), 'DD/MM/YYYY').toDate(),
      filterDate: {
        start: moment(new Date(), "DD/MM/YYYY").startOf('day').toDate(),
        end: moment(new Date(), "DD/MM/YYYY").endOf('day').toDate()
      },
      branch: this.$store.state.branch
        ? this.$store.state.branch
        : "all",

    }
  },
  methods: {

    onDateChange(newDate) {
      this.$nextTick(() => {
        this.triggerSearchData()
      })
    },
    handleFilterDateSearchType(type) {
      this.search_date_type = type
      this.triggerSearchData()
    },
    triggerInitData() {
      if (this.currentMenu === "OPD") {
        this.$refs.opdTab.$refs.PatientSummaryTable.initData()
      } else if (this.currentMenu === "IPD") {
        this.$refs.ipdTab.$refs.PatientSummaryTable.initData()
      } else if (this.currentMenu === "REFERRAL") {
        this.$refs.referralTab.$refs.PatientSummaryTable.initData()
      }
    },

    triggerSearchData() {
      if (this.currentMenu === "OPD") {
        this.$refs.opdTab.$refs.PatientSummaryTable.SearchData()
      } else if (this.currentMenu === "IPD") {
        this.$refs.ipdTab.$refs.PatientSummaryTable.SearchData()
      } else if (this.currentMenu === "REFERRAL") {
        this.$refs.referralTab.$refs.PatientSummaryTable.SearchData()
      }
    },

  },

  computed: {
    menus() {
      const menus = [
        { name: "OPD" },
        { name: "IPD" },
        { name: "REFERRAL" },
      ]
      return menus
    },
  }
};
</script>
