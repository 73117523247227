<template>
    <div class="container-fluid z-2">
        <b-modal id="referral-patient-summary-modal" hide-footer size="xl" no-close-on-backdrop>
            <template #modal-title>
                <div class="d-flex">

                    <div class="">
                        <b>Patient Name:</b>
                        {{ patient_summary.patientName }}
                    </div>
                    <div class="ml-3">
                        <b>HN:</b>
                        {{ patient_summary.HN }}
                    </div>
                    <div class="ml-3">
                        <b>Concession Type:</b>
                        {{ patient_summary.concessionType || '' }}
                    </div>
                    <div class="ml-3">
                        <b>Insurance:</b>
                        {{ patient_summary.insurance || '' }}
                    </div>
                    <button class="ml-2 btn btn-primary" @click="GoToPatient">Patient
                        Information</button>



                </div>
            </template>

            <div class="row bg-blue">
                <div class="col-3">
                    Time :
                    <MazPicker v-model="patient_summary.time" :format="`DD/MM/YYYY HH:mm`"
                        :formatted="`DD/MM/YYYY HH:mm`" placeholder="Time" />
                </div>
                <div class="col-3 ">
                    Service Type :
                    <MazSelect :placeholder="'Service Type'" v-model="patient_summary.serviceType"
                        :options="prepared_serviceTypes" search />
                </div>
                
                
            </div>
            <div class="row bg-blue">
                <div class="col-3">
                    Refer to :
                    <MazSelect :placeholder="'Refer to'" v-model="patient_summary.referTo" :options="prepared_referToes"
                        search />
                    <div v-if="patient_summary.referTo == 'Other'">
                        <MazInput placeholder="Other Refer to" v-model="patient_summary.referTo_other" />
                    </div>
                </div>
                <div class="col-3">
                    Type of Boat :
                    <MazSelect :placeholder="'Type of Boat'" v-model="patient_summary.typeOfBoat"
                        :options="[{ label: 'Ferry', value: 'Ferry' }, { label: 'Speed boat', value: 'Speed boat' }]" />
                </div>
                <div class="col-3">
                    Shift :
                    <MazSelect :placeholder="'Type of Boat'" v-model="patient_summary.shift"
                        :options="[{ label: 'On call', value: 'On call' }, { label: 'On duty', value: 'On duty' }]" />
                </div>
                <div class="col-3">
                    Staff Refer :
                    <MazSelect :placeholder="'Staff Refer'" v-model="patient_summary.staff_refer" :options="staff"
                        multiple search />
                </div>
                
                
            </div>

            <div class="row bg-green">
                <div class="col-12 d-flex" v-for="(i, index) in patient_summary.diagnosis" :key="index">
                    <div class="flex-fill">
                        Diagnosis :
                        <icd10-select v-model="patient_summary.diagnosis[index]" :iNo="index"></icd10-select>
                    </div>
                    <div class="ml-2">
                        <button type="button" class="btn btn-primary"
                            @click="addRowList('patient_summary', 'diagnosis', 1)">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="ml-2">
                        <button type="button" class="btn btn-secondary"
                            @click="removeRowList('patient_summary', 'diagnosis', index)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row bg-green d-flex mt-3">

                <div v-for="(item, index) in patient_summary.treatments" :key="index" class="col-12 mb-3">
                    <div class="row">
                        <div class="col-8">
                            <div class="d-flex">
                                <div class="flex-fill">

                                    Treatment :
                                    <MazSelect placeholder="Treatment" v-model="item.treatment"
                                        :options="prepared_treatments" search />
                                    <div v-if="item.treatment == 'Other'">
                                        <MazInput placeholder="Other Treatment" v-model="item.treatment_other" />
                                    </div>

                                </div>
                                <div class="ml-2 mt-3">
                                    <button type="button" class="btn btn-primary"
                                        @click="addRowListt('patient_summary', 'treatments', 1)">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                                <div class="ml-2 mt-3">
                                    <button type="button" class="btn btn-secondary"
                                        @click="removeRowList('patient_summary', 'treatments', index)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            Area :
                            <MazInput :placeholder="'Area'" v-model="item.area" />
                        </div>
                        <div class="col-2">
                            Unit :
                            <MazInput :placeholder="'Unit'" v-model="item.unit" />
                        </div>
                        <div class="col-3">
                            Order :
                            <MazSelect :placeholder="'Order'" v-model="item.order" :options="staff" multiple search />
                        </div>
                        <div class="col-3">
                            Practice :
                            <MazSelect :placeholder="'Practice'" v-model="item.practice" :options="staff" multiple
                                search />
                        </div>
                    </div>
                </div>

            </div>

            <div class="row bg-yellow d-flex justify-content-center">
                <div class="col-6">
                    Note:
                    <textarea :placeholder="'Note'" v-model="patient_summary.note" cols="60" rows="3"
                        class="ml-3"></textarea>
                </div>
                <div class="col-3 ">
                    Bill Log :
                    <MazSelect :placeholder="'Bill Log'" v-model="patient_summary.billLog" :options="staff" multiple
                        search />
                </div>
                <div class="col-3">
                    Med Log :
                    <MazSelect :placeholder="'Med Log'" v-model="patient_summary.medLog" :options="staff" multiple
                        search />
                </div>

            </div>
            <div class="d-flex justify-content-end">
                <div>
                    <button class="btn btn-primary" @click="savePatientSummary()">Save</button>
                </div>
                <div class="ml-3">
                    <button class="btn btn-danger" @click="deletePatientSummary()">Delete</button>
                </div>


            </div>
        </b-modal>
    </div>
</template>

<script>

import { db } from "../../db";
import moment from "moment";
export default {
    props: ['patient_summary'],
    firestore() {
        return {
            rejects: db.collection("Reject"),
            treatments: db.collection("Treatment"),
            senders: db.collection("Sender"),
            serviceTypes: db.collection("ServiceType"),
            referTo: db.collection("ReferTo")
        }
    },
    data() {
        return {
            staff: [],
            rejects: [],
            treatments: [],
            senders: [],
            serviceTypes: [],
            referTo: [],
        }
    },

    watch: {

        'patient_summary.treatments': {
            handler(newVal) {
                newVal.forEach((item) => {
                    if (item.treatment !== 'Other' && item.treatment_other) {
                        item.treatment_other = ''
                    }
                })
            },
            deep: true,

        },
        'patient_summary.referTo'(newVal) {
            if (newVal && newVal !== 'Other') {
                this.patient_summary.referTo_other = ''
            }

        },
        'patient_summary.sender'(newVal) {
            if (newVal && newVal !== 'Other') {
                this.patient_summary.sender_other = ''
            }
        },
    },
    mounted() {
        this.fetchStaff()
    },
    methods: {
        async fetchStaff() {       
            try {
                const [doctorSnap, insuranceSnap, nurseSnap, paramedicSnap, publicHealthSnap] = await Promise.all([
                    db.collection("doctor_lists").get(),
                    db.collection("insurance_lists").get(),
                    db.collection("nurse_lists").get(),
                    db.collection("paramedic_lists").get(),
                    db.collection("publicHealth_lists").get(),
                ])

                this.doctor = doctorSnap.docs.map(doc => doc.data())
                this.insurance = insuranceSnap.docs.map(doc => doc.data())
                this.nurse = nurseSnap.docs.map(doc => doc.data())
                this.paramedic = paramedicSnap.docs.map(doc => doc.data())
                this.publicHealth = publicHealthSnap.docs.map(doc => doc.data())
                this.staff = [
                    ...this.doctor,
                    ...this.insurance,
                    ...this.nurse,
                    ...this.paramedic,
                    ...this.publicHealth,
                ]

            } catch (error) {
                console.error("Error fetching staff data:", error)
            }

        },
        async GoToPatient() {
            let HN = this.patient_summary.HN
            const routeData = this.$router.resolve(`/patient/${HN}/billing`)
            window.open(routeData.href, '_blank')

        },
        addRowList(modelGrp, model, value) {
            if (modelGrp && model) {
                this[modelGrp][model].push({ code: "", title: "" })
                this[modelGrp][model + "Length"] += value
            } else {
                this[model].push({})
                this[model + "Length"] += value
            }
        },
        removeRowList(modelGrp, model, index) {
            if (modelGrp && model && index) {
                if (this[modelGrp][model].length > 1) {
                    this[modelGrp][model].splice(index, 1)
                }
            }
        },
        addRowListr(modelGrp, model, value) {
            if (modelGrp && model) {
                this[modelGrp][model].push({ reject: "", reason: "", problem: "" })
                this[modelGrp][model + "Length"] += value
            } else {
                this[model].push({})
                this[model + "Length"] += value
            }
        },
        addRowListt(modelGrp, model, value) {
            if (modelGrp && model) {
                this[modelGrp][model].push({ treatment: "", area: "", unit: "" })
                this[modelGrp][model + "Length"] += value
            } else {
                this[model].push({})
                this[model + "Length"] += value
            }
        },

        async savePatientSummary() {
            let loader = this.$loading.show({})

            try {
                this.patient_summary.time = moment(this.patient_summary.time, "DD/MM/YYYY HH:mm").toDate()
                delete this.patient_summary.vgt_id
                delete this.patient_summary.originalIndex
                const patient_summary = { ...this.patient_summary }
                const cleanedData = this.replaceUndefinedWithNull(patient_summary)

                const querySnapshot = await db.collection("ReferralPatientSummary")
                    .where("HN", "==", cleanedData.HN)
                    .where("time", "==", cleanedData.time)
                    .get()

                if (!querySnapshot.empty) {
                    querySnapshot.forEach(async (doc) => {
                        await db.collection("ReferralPatientSummary").doc(doc.id).update(cleanedData)
                    })
                    this.$alert("Edit Patient Summary Success.", null, "success")
                    this.$emit("saved")
                } else {
                    let docRef = await db.collection("ReferralPatientSummary").add(cleanedData)
                    await db.collection("ReferralPatientSummary").doc(docRef.id).update({ id: docRef.id })
                    this.$alert("Save Patient Summary Success.", null, "success")
                    this.$emit("saved")
                }


                if (cleanedData.treatment_other && cleanedData.treatment_other !== null) {
                    await db.collection("Treatment").doc(cleanedData.treatment_other).set({
                        label: cleanedData.treatment_other,
                        value: cleanedData.treatment_other,
                    })
                }
                if (cleanedData.referTo_other && cleanedData.referTo_other !== null) {
                    await db.collection("ReferTo").doc(cleanedData.referTo_other).set({
                        label: cleanedData.referTo_other,
                        value: cleanedData.referTo_other,
                    })
                }

            } catch (error) {
                this.$alert(`error ${error}`, null, "error")
                loader.hide()
            }
            loader.hide()
            this.$bvModal.hide("referral-patient-summary-modal")

        },
        replaceUndefinedWithNull(obj) {
            return Object.fromEntries(
                Object.entries(obj)
                    .map(([key, value]) => [key, value === undefined ? null : value])
            )
        },
        async deletePatientSummary() {
            let loader = this.$loading.show({})
            await db.collection('ReferralPatientSummary').doc(this.patient_summary.id).delete()
            loader.hide()
            this.$alert("Delete Patient Summary Success.", null, "success")
            this.$bvModal.hide("referral-patient-summary-modal")
            this.$emit("delete")
        }

    },
    computed: {
        prepared_rejects() {
            const blankOptions = [{ label: "", value: null }]
            const otherOptions = [{ label: "Other", value: "Other" }]

            return [
                ...blankOptions,
                ...this.rejects.map((i) => ({
                    label: i.label,
                    value: i.value,
                })),
                ...otherOptions
            ]

        },
        prepared_treatments() {
            const blankOptions = [{ label: "", value: null }]
            const otherOptions = [{ label: "Other", value: "Other" }]

            return [
                ...blankOptions,
                ...this.treatments.map((i) => ({
                    label: i.label,
                    value: i.value,
                })),
                ...otherOptions
            ]

        },
        prepared_senders() {
            const blankOptions = [{ label: "", value: null }]
            const otherOptions = [{ label: "Other", value: "Other" }]

            return [
                ...blankOptions,
                ...this.senders.map((i) => ({
                    label: i.label,
                    value: i.value,
                })),
                ...otherOptions
            ]

        },
        prepared_serviceTypes() {
            const blankOptions = [{ label: "", value: null }]
            const otherOptions = [{ label: "Other", value: "Other" }]

            return [
                ...blankOptions,
                ...this.serviceTypes.map((i) => ({
                    label: i.label,
                    value: i.value,
                })),
                ...otherOptions
            ]

        },
        prepared_referToes() {
            const blankOptions = [{ label: "", value: null }]
            const otherOptions = [{ label: "Other", value: "Other" }]

            return [
                ...blankOptions,
                ...this.referTo.map((i) => ({
                    label: i.label,
                    value: i.value,
                })),
                ...otherOptions
            ]

        },
    }

}
</script>

<style lang="scss"></style>