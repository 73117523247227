<template>
  <div>

    <vue-good-table :columns="columns" :rows="filteredItems" :lineNumbers="true" :sort-options="{ enabled: false, }"
      class="referral-patient-summary-table" :group-options="{ enabled: true }" style="z-index: 0;"
      @on-row-click="showPatient">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'patientDetails'">
          <div class="text-dark">
            <b>{{ props.row.patientName }} </b>
          </div>
          <div class="text-muted">
            <i>HN: {{ props.row.HN }}</i>
          </div>
          <hr>
          <div v-if="props.row.serviceType">
            <b>Service Type :</b> <span class="text-info">{{ props.row.serviceType_other ? props.row.serviceType_other :
              props.row.serviceType }}</span>
          </div>
          <div v-if="props.row.sender">
            <b>Senders :</b> <span class="text-info">{{ props.row.sender_other ? props.row.sender_other :
              props.row.sender }}</span>
          </div>
        </div>
        <div v-else-if="props.column.field == 'time'">
          <h5>{{ formatTime(props.row.time) }}</h5>
        </div>
        <div v-else-if="props.column.field == 'concession'">
          <div v-if="props.row.concessionType == 'Resident'">
            <span class="font-weight-bold resident-row">
              {{ props.row.concessionType }}
            </span>
          </div>
          <div v-if="props.row.concessionType == 'Foreigner'">
            <span class="font-weight-bold foreigner-row">
              {{ props.row.concessionType }}
            </span>
          </div>
          <div v-if="props.row.concessionType == 'Insurance'">
            <span class="font-weight-bold insurance-row">
              {{ props.row.concessionType }}
            </span>
          </div>
          <hr>
          {{ props.row.insurance ? props.row.insurance : '-' }}
          <div class="mt-2">
            <span><b>Bill Log : </b></span>
            <span class="mt-2 mb-2" v-if="(props.row.billLog && props.row.billLog.length > 0)">
              <span v-for="(name, index) in props.row.billLog" :key="index" :class="getRoleClass(name)" class="mr-3"
                style="font-size: 75%;">
                {{ name }}
              </span>
            </span>
          </div>
        </div>
        <div v-else-if="props.column.field == 'referDetails'">
          <div v-if="props.row.referTo">
            <b>Refer to :</b> {{ props.row.referTo }}
          </div>
          <div v-if="props.row.typeOfBoat">
            <b>Type of Boat :</b> {{ props.row.typeOfBoat }}
          </div>
          <div v-if="props.row.shift">
            <b>Shift :</b> {{ props.row.shift }}
          </div>

          <div v-if="props.row.staff_refer && props.row.staff_refer.length > 0" class="mt-3">
            <hr>
            <span><b>Staff Refer:</b></span>
            <span v-for="(name, index) in props.row.staff_refer" :key="props.row.HN + '-staffrefer-' + index"
              :class="getRoleClass(name)" class="ml-2" style="font-size: 75%;">
              {{ getNameTitle(name) }}
            </span>
          </div>
        </div>
        <div v-else-if="props.column.field == 'diagnosis&treatment'">

          <div v-for="(item, index) in props.row.diagnosis" :key="props.row.HN + '-diagnosis-' + index"
            class="diag-class">
            <b v-if="index === 0" class="mr-3">Diagnosis :</b>
            <span class="text-info font-weight-bold">{{ item.title ? (index + 1 + '. ') + item.title : '' }}</span>
          </div>
          <div class="mt-2">
            <span><b>Med Log : </b></span>
            <span class="mt-2" v-if="(props.row.medLog && props.row.medLog.length > 0)">
              <span v-for="(name, index) in props.row.medLog" :key="index" :class="getRoleClass(name)" class="mr-3"
                style="font-size: 75%;">
                {{ name }}
              </span>
            </span>
          </div>
          <hr>


          <div v-for="(item, index) in props.row.treatments" :key="props.row.HN + '-treatment-' + index">

            <div class="row"
              :class="{ 'attend-class': item.treatment === 'Attended', 'observe-class': item.treatment === 'Observation', 'text-info': item.treatment !== 'Observation' && item.treatment !== 'Attended' }">

              <div class="col-6">
                <!-- <b v-if="index === 0" class="mr-3">Treatment :</b> #ffee93 -->
                <span class="font-weight-bold">
                  {{ item.treatment_other ? (index + 1 + '. ') + item.treatment_other : (item.treatment ? (index + 1 +
                    '.') + item.treatment : '') }}
                </span>

              </div>
              <div class="col-3" v-if="item.area">
                <b>Area: </b> <span class="text-info">{{ item.area }}</span>
              </div>
              <div class="col-3" v-if="item.unit">
                <b>Unit: </b> <span class="text-info">{{ item.unit }}</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6" v-if="item.order && item.order.length > 0">
                <span><b>Order : </b></span>
                <span v-for="(name, index) in item.order" :key="props.row.HN + '-order-' + index"
                  :class="getRoleClass(name)" style="font-size: 75%;">
                  {{ getNameTitle(name) }}
                </span>
              </div>
              <div class="col-6" v-if="item.practice && item.practice.length > 0">
                <span><b>Practice : </b></span>
                <span v-for="(name, index) in item.practice" :key="props.row.HN + '-practice-' + index"
                  :class="getRoleClass(name)" style="font-size: 75%;">
                  {{ getNameTitle(name) }}
                </span>
              </div>
            </div>
            <hr v-if="index < props.row.treatments.length - 1">

          </div>


        </div>
        <div v-else-if="props.column.field == 'note'">
          <div>
            {{ props.row.note || '-' }}
          </div>
        </div>
      </template>
    </vue-good-table>
    <referal-patient-summary-modal :patient_summary="patient_summary" @saved="SearchData"
      @delete="SearchData"></referal-patient-summary-modal>
  </div>
</template>

<script>
import { db } from "../../db";
import moment from "moment";

export default {
  props: ['dateStart', 'dateEnd', 'branch'],
  data() {
    return {
      staff: [],
      patient_summarys: [],
      patient_summary: {},
      isEditPatient: false,
      doctor: [],
      insurance: [],
      nurse: [],
      paramedic: [],
      publicHealth: [],
      columns: [
        { label: "Time", field: "time", width: "75px" },
        { label: "Patient Details", field: "patientDetails", width: "270px" },
        { label: "Consession", field: "concession", width: "170px" },
        { label: "Refer Details", field: "referDetails", width: "220px" },
        { label: "Diagnosis & Treatment", field: "diagnosis&treatment", thClass: 'med-tab', width: "400px" },
        { label: "Note", field: "note", thClass: 'note-tab', width: "220px" },

      ],


    };
  },
  mounted() {
    this.fetchStaff()
    this.SearchData()
  },
  methods: {
    async fetchStaff() {
      try {
        const [doctorSnap, insuranceSnap, nurseSnap, paramedicSnap, publicHealthSnap] = await Promise.all([
          db.collection("doctor_lists").get(),
          db.collection("insurance_lists").get(),
          db.collection("nurse_lists").get(),
          db.collection("paramedic_lists").get(),
          db.collection("publicHealth_lists").get(),
        ])

        this.doctor = doctorSnap.docs.map(doc => doc.data())
        this.insurance = insuranceSnap.docs.map(doc => doc.data())
        this.nurse = nurseSnap.docs.map(doc => doc.data())
        this.paramedic = paramedicSnap.docs.map(doc => doc.data())
        this.publicHealth = publicHealthSnap.docs.map(doc => doc.data())
        this.staff = [
          ...this.doctor,
          ...this.insurance,
          ...this.nurse,
          ...this.paramedic,
          ...this.publicHealth,
        ]

      } catch (error) {
        console.error("Error fetching staff data:", error)
      }

    },
    getNameTitle(name) {
      if (this.doctor.some(doctor => doctor.label === name)) {
        return `Dr. ${name}`
      }
      return name
    },
    getRoleClass(name) {
      if (this.doctor.some(element => element.label === name)) {
        return "doctor"
      }

      if (this.insurance.some(element => element.label === name)) {
        return "insurance"
      }

      if (this.nurse.some(element => element.label === name)) {
        return "nurse"
      }

      if (this.paramedic.some(element => element.label === name)) {
        return "paramedic"
      }

      if (this.publicHealth.some(element => element.label === name)) {
        return "publicHealth"
      }

      return ""
    },

    formatTime(time) {
      return moment(time, "DD/MM/YYYY HH:mm").format("HH:mm")
    },
    showPatient(params) {
      if (params) {
        this.patient_summary = {
          id: params.row.id,
          ...params.row,
        }
        this.isEditPatient = true
      } else {
        this.isEditPatient = false
        this.patient_summary = {}
      }
      this.$bvModal.show("referral-patient-summary-modal")
    },
    async SearchData() {
      const loader = this.$loading.show({})
      this.patient_summarys = []
      let patient_summary = {}
      try {

        let start = moment(this.dateStart, "DD/MM/YYYY").startOf('day').toDate()
        let end = moment(this.dateEnd, "DD/MM/YYYY").endOf('day').toDate()

        let patientSummaryRef = await db.collection('ReferralPatientSummary')
          .where("time", ">=", start)
          .where("time", "<", end)
          .get()

        for (let patient_summarys of patientSummaryRef.docs) {
          patient_summary = patient_summarys.data()
          patient_summary.time = patient_summary.time.toDate()
          this.patient_summarys.push(patient_summary)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {

        loader.hide()
      }
    },
    extractDate(key) {
      const match = key.match(/\d{2}\/\d{2}\/\d{4}/)
      return match ? new Date(match[0].split('/').reverse().join('-')) : new Date(0)
    },

  },
  computed: {
    filteredItems() {
      let items = _.clone(this.patient_summarys)

      let itemGroups = []
      let groupedItems = this._.groupBy(items, item => {

        return item.type + ' ' + moment(item.time, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY")
      })
      const sortedItems = Object.entries(groupedItems)
        .sort((a, b) => {
          const dateA = this.extractDate(a[0])
          const dateB = this.extractDate(b[0])
          if (dateA - dateB !== 0) {
            return dateA - dateB
          }
          if (a[0].startsWith("OPD") && b[0].startsWith("IPD")) {
            return -1
          } else if (a[0].startsWith("IPD") && b[0].startsWith("OPD")) {
            return 1
          }
          return 0
        })
        const orderedGroupedItems = Object.fromEntries(sortedItems)
      for (let group in orderedGroupedItems) {
        let groupLabel = {
          mode: "span",
          label: group,
          html: false,
          children: []
        }

        for (let item of groupedItems[group]) {
          groupLabel.children.push(item)
        }

        itemGroups.push(groupLabel)
      }

      return itemGroups
    }


  }
}
</script>

<style lang="scss">
.doctor {
  font-weight: bold;
  background-color: #fff;
  color: #274e13ff;
  padding: 3px;
  border: 3px solid #274e13ff;
  border-radius: 0.5em;
}

.insurance {
  font-weight: bold;
  background-color: #fff;
  color: #3c78d8ff;
  padding: 3px;
  border: 3px solid #3c78d8ff;
  border-radius: 0.5em;
}

.nurse,
.paramedic {
  font-weight: bold;
  background-color: #fff;
  color: #1d800eff;
  padding: 3px;
  border: 3px solid #1d800eff;
  border-radius: 0.5em;
}

.publicHealth {
  font-weight: bold;
  background-color: #fff;
  color: #a4c964ff;
  padding: 3px;
  border: 3px solid #a4c964ff;
  border-radius: 0.5em;
}

.attend-class {
  background-color: #1d800eff;
  color: #fff !important;
  margin-right: -10px;
  margin-left: -10px;
}

.observe-class {
  background-color: #a4c964ff;
  color: #fff !important;
  margin-right: -10px;
  margin-left: -10px;
}

.diag-class {
  background-color: #fcf5c7;

  margin-right: -10px;
  margin-left: -10px;
}

.referral-patient-summary-table {

  .vgt-table.bordered td,
  .vgt-table.bordered th {
    border: 1px solid #acacac;
  }

  .med-tab {
    background-color: #274e13ff !important;
  }

  .note-tab {
    background-color: #FFC300 !important;
  }

  .resident-row {
    background-color: #A0A0A0;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .foreigner-row {
    background-color: #A0A0A0;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .insurance-row {
    background-color: #1565C0;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .hospital-row {

    background-color: #1976D2;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .slinky-row {

    background-color: #D32F2F;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .aesthetic-row {

    background-color: #FBC02D;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .pier-row {

    background-color: #388E3C;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .central-row {

    background-color: #7B1FA2;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

}
</style>