<template>
  <div>
    <vue-good-table :columns="columns" :rows="filteredItems" :lineNumbers="true" :sort-options="{ enabled: false, }"
      :fixed-header="true" max-height="1000px" class="patient-summary-table" :group-options="{ enabled: true }"
      style="z-index: 0;" :row-style-class="getRowClass">

      <template slot="table-row" slot-scope="props">

        <div v-if="props.column.field == 'patientDetails'">

          <div class="text-dark d-flex justify-content-between">
            <h5 class="text-primary"><b>{{ props.row.patientName }} </b></h5>
            <button class="btn ml-2" @click="showPatient(props)"> <i class="fa-solid fa-pen-to-square"
                aria-hidden="true"></i></button>

          </div>
          <div class="d-flex">
            <div>
              <i class="text-muted"><small>HN: {{ props.row.HN }}</small></i>
            </div>
            <div v-if="props.row.departure_date" class="d-flex justify-content-between ml-3">
              <div>
                Departure:
                <span class="text-info">
                  {{ formatDatetime(props.row.departure_date) }}
                </span>
              </div>

            </div>
          </div>

          <hr>
          <div v-if="props.row.branch == 'HOSPITAL'">
            <b>Branch: </b>
            <span class="font-weight-bold hospital-row">
              {{ props.row.branch }}
            </span>
          </div>
          <div v-else-if="props.row.branch == 'SLINKY REFERRAL'">
            <b>Branch: </b>
            <span class="font-weight-bold slinky-row">
              {{ props.row.branch }}
            </span>
          </div>
          <div v-else-if="props.row.branch == 'AESTHETIC'">
            <b>Branch: </b>
            <span class="font-weight-bold aesthetic-row">
              {{ props.row.branch }}
            </span>
          </div>
          <div v-else-if="props.row.branch == 'PIER REFERRAL'">
            <b>Branch: </b>
            <span class="font-weight-bold pier-row">
              {{ props.row.branch }}
            </span>
          </div>
          <div v-else-if="props.row.branch == 'CENTRAL REFERRAL'">
            <b>Branch: </b>
            <span class="font-weight-bold central-row">
              {{ props.row.branch }}
            </span>
          </div>
          <div v-if="props.row.serviceType">
            <b>Service Type :</b> <span class="text-info">{{ props.row.serviceType_other ? props.row.serviceType_other :
              props.row.serviceType }}</span>
          </div>
          <div v-if="props.row.sender">
            <b>Senders :</b> <span class="text-info">{{ props.row.sender_other ? props.row.sender_other :
              props.row.sender }}</span>
            
          </div>
          <div v-if="(props.row.senderLog && props.row.senderLog.length > 0)">
            <b> Sender Log : </b>
            <span class="mt-2 mb-2" v-if="(props.row.senderLog && props.row.senderLog.length > 0)">
              <span v-for="(name, index) in props.row.senderLog" :key="index" :class="getRoleClass(name)"
                style="font-size: 75%;">
                {{ name }}
              </span>
            </span>
          </div>
        </div>

        <div v-else-if="props.column.field == 'time'">

          <h5>{{ formatTime(props.row.time) }}</h5>
          <div v-if="props.row.departure_date">
            <circle-icon :value="getDayLeft(props.row.departure_date)" :size="30"
              :color="getColor(getDayLeft(props.row.departure_date))" v-if="getDayLeft(props.row.departure_date) > 0" />
            <div v-else-if="getDayLeft(props.row.departure_date) == 0">
              <span class="text-danger font-weight-bold">today</span>
            </div>
            <stop-icon :size="30" :color="'red'" v-else />
          </div>
        </div>
        <div v-else-if="props.column.field == 'concession'">
          <!-- true data -->
          <div v-if="props.row.payment_status && props.row.payment_status.length">

            <div v-for="(status, index) in props.row.payment_status[0].status" :key="index">
              <div class="d-flex justify-content-between">
                <div class="mt-2">
                  <span class="font-weight-bold" :class="{
                    'resident-row': props.row.payment_status[0].concession_type[index] == 'Resident',
                    'foreigner-row': props.row.payment_status[0].concession_type[index] == 'Foreigner',
                    'insurance-row': props.row.payment_status[0].concession_type[index] == 'Insurance',
                  }">
                    {{ props.row.payment_status[0].concession_type[index] }}
                  </span>

                  <span class="paymentstatus-row font-weight-bold" :class="{
                    'badge-success': status === 'paid',
                    'badge-warning': status === 'gop',
                    'badge-danger': status === 'waiting for payment' || status === 'denied' || status === 'unpaid' || status === 'outstanding',
                    'badge-dark': status === 'cancelled',
                  }">
                    {{ status === 'outstanding' ? "OUTSTAND" : status.toUpperCase() }}
                  </span>
                </div>
                <div>
                  <button class="btn btn-light text-white"
                    @click="showInvoice(props.row.payment_status[0].invoice_id[index])">
                    <i class="fas fa-receipt" style='color:#0b97e5'></i>
                  </button>
                </div>
              </div>

            </div>
          </div>
          <!-- true data -->
          <!-- temporary data -->
          <div v-else-if="props.row.concessionType && props.row.status">
            <span class="font-weight-bold" :class="{
              'resident-row': props.row.concessionType == 'Resident',
              'foreigner-row': props.row.concessionType == 'Foreigner',
              'insurance-row': props.row.concessionType == 'Insurance',
            }">
              {{ props.row.concessionType }}
            </span>
            <span class="paymentstatus-row font-weight-bold" :class="{
              'badge-success': props.row.status === 'paid',
              'badge-warning': props.row.status === 'gop',
              'badge-danger': props.row.status === 'waiting for payment' || props.row.status === 'denied' || props.row.status === 'unpaid' || props.row.status === 'outstanding',
              'badge-dark': props.row.status === 'cancelled',
            }">
              {{ props.row.status.toUpperCase() }}
            </span>
          </div>
          <!-- temporary data -->


          <hr>
          {{ props.row.insurance ? props.row.insurance : '-' }}
          <div class="mt-2">
            <span><b>Bill Log : </b></span>
            <span class="mt-2 mb-2" v-if="(props.row.billLog && props.row.billLog.length > 0)">
              <span v-for="(name, index) in props.row.billLog" :key="index" :class="getRoleClass(name)" class="mr-3"
                style="font-size: 75%;">
                {{ name }}
              </span>
            </span>
          </div>




        </div>

        <div v-else-if="props.column.field == 'reject'">
          <div v-for="(item, index) in props.row.rejects" :key="props.row.HN + '-reject-' + index">
            <div class="d-flex">
              <div class="font-weight-bold ">
                {{ item.reject_other ? (index + 1 + '.') + item.reject_other : (item.reject ? (index + 1 + '.') +
                  item.reject : '') }}
              </div>
            </div>
            <div v-if="item.reason">
              <b>Reason : </b> <span class="text-danger">{{ item.reason || '' }}</span>
            </div>
            <div v-if="item.problem">
              <b>Problem : </b> <span class="text-danger">{{ item.problem || '' }}</span>
            </div>
          </div>
          <div v-if="props.row.retry && props.row.retry.length > 0">
            <hr>
            <span><b>Retry: </b></span>
            <span v-for="(name, index) in props.row.retry" :key="props.row.HN + '-retry-' + index"
              :class="getRoleClass(name)" class="ml-2" style="font-size: 75%;">
              {{ getNameTitle(name) }}
            </span>
          </div>
        </div>
        <div v-else-if="props.column.field == 'diagnosis&treatment'">

          <div v-for="(item, index) in props.row.diagnosis" :key="props.row.HN + '-diagnosis-' + index"
            class="diag-class">
            <b v-if="index === 0" class="mr-3">Diagnosis :</b>
            <span class="text-info font-weight-bold">{{ item.title ? (index + 1 + '. ') + item.title : '' }}</span>
          </div>
          <div class="mt-2">
            <span><b>Med Log : </b></span>
            <span class="mt-2" v-if="(props.row.medLog && props.row.medLog.length > 0)">
              <span v-for="(name, index) in props.row.medLog" :key="index" :class="getRoleClass(name)" class="mr-3"
                style="font-size: 75%;">
                {{ name }}
              </span>
            </span>
          </div>

          <hr>

          <div v-for="(item, index) in props.row.treatments" :key="props.row.HN + '-treatment-' + index">

            <div class="row"
              :class="{ 'attend-class': item.treatment === 'Attended', 'observe-class': item.treatment === 'Observation', 'text-info': item.treatment !== 'Observation' && item.treatment !== 'Attended' }"
              :data-treatment="props.row.treatment">

              <div class="col-6">
                <span class="font-weight-bold">
                  {{ item.treatment_other ? (index + 1 + '. ') + item.treatment_other : (item.treatment ? (index + 1 +
                    '. ') + item.treatment : '') }}
                </span>

              </div>
              <div class="col-3" v-if="item.area">
                <b>Area: </b> <span class="text-info">{{ item.area }}</span>
              </div>
              <div class="col-3" v-if="item.unit">
                <b>Unit: </b> <span class="text-info">{{ item.unit }}</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6" v-if="item.order && item.order.length > 0">
                <span><b>Order : </b></span>
                <span v-for="(name, index) in item.order" :key="props.row.HN + '-order-' + index"
                  :class="getRoleClass(name)" style="font-size: 75%;">
                  {{ getNameTitle(name) }}
                </span>
              </div>
              <div class="col-6" v-if="item.practice && item.practice.length > 0">
                <span><b>Practice : </b></span>
                <span v-for="(name, index) in item.practice" :key="props.row.HN + '-practice-' + index"
                  :class="getRoleClass(name)" style="font-size: 75%;">
                  {{ getNameTitle(name) }}
                </span>
              </div>
            </div>
            <hr v-if="index < props.row.treatments.length - 1">

          </div>


        </div>
        <div v-else-if="props.column.field == 'note'">
          <div>
            <span class="text-info">{{ props.row.note || '' }}</span>
          </div>

        </div>

      </template>
    </vue-good-table>
    <patient-summary-modal :patient_summary="patient_summary" @saved="SearchData" @delete="SearchData" />
    <patient-invoice-modal :invoice="invoice" :visits="visits" @saveInvoice="SearchData" @deleteInvoice="SearchData" />

  </div>
</template>

<script>
import { db } from "../../db";
import moment from "moment";

export default {
  props: ['dateStart', 'dateEnd', 'branch'],
  firestore() {
    return {
      insurances: db.collection("Insurance"),

    }
  },
  data() {
    return {
      staff: [],
      patient_summarys: [],
      temporaryPatientSummarys: [],
      patient_summary: {},
      isEditPatient: false,
      doctor: [],
      insurance: [],
      nurse: [],
      paramedic: [],
      publicHealth: [],
      invoice: [],
      visits: [],
      columns: [
        { label: "Time", field: "time", width: "75px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },
        { label: "Patient Details", field: "patientDetails", width: "290px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },
        { label: "Payment Status", field: "concession", width: "220px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },
        { label: "Reject", field: "reject", thClass: 'reject-tab', width: "300px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },
        { label: "Diagnosis & Treatment", field: "diagnosis&treatment", thClass: 'med-tab', width: "400px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },
        { label: "Note", field: "note", thClass: 'note-tab', width: "170px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },
      ],
    }
  },
  mounted() {
    this.fetchStaff()
    this.SearchData()

  },
  methods: {
    getRowClass(row) {
      if (!row.treatments || row.treatments.length === 0) {
        return '' // Default class if no treatments exist
      }

      const hasAttended = row.treatments.some(treatment => treatment.treatment === 'Attended')
      const hasObservation = row.treatments.some(treatment => treatment.treatment === 'Observation')

      if (hasAttended) {
        return 'row-attended' // Class for rows with "Attended" treatment
      } else if (hasObservation) {
        return 'row-observation' // Class for rows with "Observation" treatment
      }

      return ''; // Default class
    },
    async migrate_data() {
      try {
        // Retrieve all documents from the PatientSummary collection
        const patientSummaryRef = await db.collection('PatientSummary').get()
        const batch = db.batch()

        // Iterate through each document in the PatientSummary collection
        for (const doc of patientSummaryRef.docs) {
          const data = doc.data()

          // Assume there's a patientId field in the PatientSummary document to reference the Patient document
          if (data.HN) {
            // Retrieve the corresponding Patient document
            const patientDoc = await db.collection('Patient').doc(data.HN).get()

            if (patientDoc.exists) {
              const patientData = patientDoc.data()

              // Add the departure_date field to the PatientSummary data
              data.departure_date = patientData.departure_date || null
            } else {
              console.warn(`Patient document not found for patientId: ${data.patientId}`)
            }
          } else {
            console.warn(`No patientId found in PatientSummary document with ID: ${doc.id}`)
          }

          // Add the update to the batch
          batch.update(doc.ref, data)
        }

        // Commit the batch update
        await batch.commit();
        console.log('Migration completed successfully!');
      } catch (error) {
        console.error('Error during migration:', error);
      }

    },
    async fetchStaff() {
      try {
        const [doctorSnap, insuranceSnap, nurseSnap, paramedicSnap, publicHealthSnap] = await Promise.all([
          db.collection("doctor_lists").get(),
          db.collection("insurance_lists").get(),
          db.collection("nurse_lists").get(),
          db.collection("paramedic_lists").get(),
          db.collection("publicHealth_lists").get(),
        ])

        this.doctor = doctorSnap.docs.map(doc => doc.data())
        this.insurance = insuranceSnap.docs.map(doc => doc.data())
        this.nurse = nurseSnap.docs.map(doc => doc.data())
        this.paramedic = paramedicSnap.docs.map(doc => doc.data())
        this.publicHealth = publicHealthSnap.docs.map(doc => doc.data())
        this.staff = [
          ...this.doctor,
          ...this.insurance,
          ...this.nurse,
          ...this.paramedic,
          ...this.publicHealth,
        ]

      } catch (error) {
        console.error("Error fetching staff data:", error)
      }

    },
    changeColorClass(value) {

      if (value == true) {
        return 'temporary-row'
      }

      else {
        return ''
      }

    },
    getNameTitle(name) {
      if (this.doctor.some(doctor => doctor.label === name)) {
        return `Dr. ${name}`
      }
      return name
    },
    getRoleClass(name) {
      if (this.doctor.some(element => element.label === name)) {
        return "doctor"
      }

      if (this.insurance.some(element => element.label === name)) {
        return "insurance"
      }

      if (this.nurse.some(element => element.label === name)) {
        return "nurse"
      }

      if (this.paramedic.some(element => element.label === name)) {
        return "paramedic"
      }

      if (this.publicHealth.some(element => element.label === name)) {
        return "publicHealth"
      }

      return ""
    },
    formatTime(time) {
      return moment(time, "DD/MM/YYYY HH:mm").format("HH:mm")
    },
    formatDatetime(time) {
      return moment(time.toDate()).format("DD/MM/YYYY")
    },
    showPatient(params) {
      if (params) {
        this.patient_summary = {
          id: params.row.id,
          ...params.row,
        }
        this.isEditPatient = true
      } else {
        this.isEditPatient = false
        this.patient_summary = {}
      }
      this.$bvModal.show("patient-summary-modal")
    },
    async showInvoice(invoiceId) {
      const InvoiceRef = await db.collection('Invoice').doc(invoiceId).get()

      if (InvoiceRef.exists) {
        const InvoiceData = InvoiceRef.data()

        this.invoice = {
          ...InvoiceData,
          issue_date: moment(InvoiceData.issue_date.toDate()).format(
            "DD/MM/YYYY HH:mm"
          ),
          visit_date: moment(InvoiceData.visit_date.toDate()).format(
            "DD/MM/YYYY HH:mm"
          ),

        }
        this.visits = await this.fetchVisits(this.invoice.HN)

        this.$bvModal.show("new-patient-invoice-modal")
      } else {
        console.log("No such document!")
      }

    },
    async fetchVisits(patientId) {
      const visits = (
        await db
          .collection("Visit")
          .where("patient", "==", db.collection("Patient").doc(patientId))
          .orderBy("visit_date", "desc")
          .get()
      ).docs.map((visit) => visit.data())

      if (!visits.length)
        this.$alert(
          "No visit information, patient is required to register new visit first before proceed.",
          null,
          "error"
        )

      return visits
    },
    async SearchData() {
      const loader = this.$loading.show({})
      this.$bvModal.hide("new-patient-invoice-modal")
      this.patient_summarys = []
      this.temporaryPatientSummarys = []
      try {

        let start = moment(this.dateStart, "DD/MM/YYYY").startOf('day').toDate()
        let end = moment(this.dateEnd, "DD/MM/YYYY").endOf('day').toDate()

        let patientSummaryRef = await db.collection('PatientSummary')
          .where("time", ">=", start)
          .where("time", "<", end)
          .get()

        for (let doc of patientSummaryRef.docs) {
          let patient_summary = doc.data()
          patient_summary.time = patient_summary.time.toDate()
          patient_summary.isTemporary = false
          if (patient_summary.departure_date) {
            patient_summary.departure_date = patient_summary.departure_date
          }
          patient_summary.treatments.map(record => {
            if (!record.hasOwnProperty('order')) {
              record.order = []
            }
            if (!record.hasOwnProperty('practice')) {
              record.practice = []
            }
            return record
          })

          patient_summary.payment_status = []

          let sod = moment(patient_summary.time).startOf('day').toDate()
          let eod = moment(patient_summary.time).endOf('day').toDate()
          const initialInvoiceRef = await db.collection('Invoice')
            .where('HN', '==', patient_summary.HN)
            .where('issue_date', '<', eod)
            .where('issue_date', '>=', sod)
            .get()

          if (initialInvoiceRef.docs.length > 0) {
            const VN = initialInvoiceRef.docs[0].data().VN
            const relatedInvoicesRef = await db.collection('Invoice')
              .where('VN', '==', VN)
              .get()
            const status = relatedInvoicesRef.docs.map(doc => doc.data().status)
            const concession_type = relatedInvoicesRef.docs.map(doc => doc.data().concession_type)
            const invoice_id = relatedInvoicesRef.docs.map(doc => doc.data().invoice_id)
            const combine_data = { "status": status, "concession_type": concession_type, "invoice_id": invoice_id }
            patient_summary.payment_status.push(combine_data)


          } else {
            patient_summary.payment_status = [{ "status": null, concession_type: null, "invoice_id": null }]
          }
          if (!patient_summary.senderLog) {
            patient_summary.senderLog = []
          }
          this.patient_summarys.push(patient_summary)
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {

        loader.hide()
      }
    },
    async initData() {
      const loader = this.$loading.show({})
      try {
        let start = moment(this.dateStart, "DD/MM/YYYY").startOf('day').toDate()
        let end = moment(this.dateEnd, "DD/MM/YYYY").endOf('day').toDate()
        let invoice = {}
        this.temporaryPatientSummarys = []
        let invoiceRef = await db.collection('Invoice')
          .where("issue_date", ">=", start)
          .where("issue_date", "<", end)
          .get()

        let filter_invoice = null
        if (this.$store.state.branch != 3) {
          filter_invoice = invoiceRef.docs.filter(doc => {
            return doc.data().HN.slice(0, 2) !== "WA"
          })
        }
        else {
          filter_invoice = invoiceRef.docs.filter(doc => {
            return doc.data().HN.slice(0, 2) === "WA"
          })
        }

        for (let invoices of filter_invoice) {
          invoice = invoices.data()
          let patient_summary = {}

          patient_summary.time = moment(invoice.issue_date.toDate()).format("DD/MM/YYYY HH:mm")
          patient_summary.HN = invoice.HN
          patient_summary.patientName = invoice.patient_name
          patient_summary.concessionType = invoice.concession_type
          patient_summary.status = invoice.status
          patient_summary.branch = ""
          patient_summary.sender = ""
          patient_summary.senderLog = []
          patient_summary.billLog = []
          patient_summary.diagnosis = [{ "code": "", "title": "" }]
          patient_summary.rejects = [{ "reject": "", "reason": "", "problem": "" }]
          patient_summary.retry = []
          patient_summary.treatments = [{ "treatment": "", "area": "", "unit": "", "order": [], "practice": [] }]
          patient_summary.medLog = []
          patient_summary.note = ""

          let visitRef = await db.collection('Visit')
            .where("VN", "==", invoice.VN)
            .get()
          visitRef.docs.forEach((visits) => {
            let visit = visits.data()
            patient_summary.visit_type = visit.visit_type
          })

          let patientRef = await db.collection('Patient')
            .where("HN", "==", invoice.HN)
            .get()
          for (let patients of patientRef.docs) {
            let patient = patients.data()
            if (patient.travel_insurance_other) {
              patient_summary.insurance = patient.travel_insurance_other
            } else if (patient.travel_insurance) {
              let insuranceRef = patient.travel_insurance

              try {
                let insuranceDoc = await insuranceRef.get()
                if (insuranceDoc.exists) {
                  let insuranceData = insuranceDoc.data()
                  patient_summary.insurance = insuranceData.name_en || null
                }
              } catch (error) {
                console.error('Error fetching insurance data:', error)
              }

            } else {
              patient_summary.insurance = null
            }

            if (patient.departure_date) {
              patient_summary.departure_date = patient.departure_date
            }

            const isDuplicate = this.patient_summarys.some(
              (summary) => summary.HN === patient_summary.HN && (moment(summary.time).format("DD/MM/YYYY HH:mm") === patient_summary.time || summary.time === patient_summary.time)
            )

            if (!isDuplicate) {
              patient_summary.isTemporary = true
              this.temporaryPatientSummarys.push(patient_summary)
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        loader.hide()
      }
    },
    extractDate(key) {
      const match = key.match(/\d{2}\/\d{2}\/\d{4}/)
      return match ? new Date(match[0].split('/').reverse().join('-')) : new Date(0)
    },
    getDayLeft(departure_date) {
      const arrival = moment().startOf('day')
      const departure = moment(departure_date.toDate())
      return departure.diff(arrival, 'days')
    },
    getColor(daysLeft) {
      if (daysLeft >= 10) return '#4CAF50'
      if (daysLeft >= 5) return '#8BC34A'
      if (daysLeft === 3 || daysLeft === 4) return '#FFEB3B'
      if (daysLeft === 1 || daysLeft === 2) return '#FFA500'
      return null
    },

  },
  computed: {

    filteredItems() {
      let items = _.clone([...this.patient_summarys, ...this.temporaryPatientSummarys])

      let itemGroups = []
      let groupedItems = this._.groupBy(items, item => {

        return item.visit_type + ' ' + moment(item.time, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY")
      })
      const sortedItems = Object.entries(groupedItems)
        .sort((a, b) => {
          const dateA = this.extractDate(a[0])
          const dateB = this.extractDate(b[0])
          if (dateA - dateB !== 0) {
            return dateA - dateB
          }
          if (a[0].startsWith("New Patient") && b[0].startsWith("Follow up")) {
            return -1
          } else if (a[0].startsWith("Follow up") && b[0].startsWith("New Patient")) {
            return 1
          }
          return 0
        })
      const orderedGroupedItems = Object.fromEntries(sortedItems)
      for (let group in orderedGroupedItems) {
        let groupLabel = {
          mode: "span",
          label: group,
          html: false,
          children: []
        }

        for (let item of groupedItems[group]) {
          groupLabel.children.push(item)
        }

        itemGroups.push(groupLabel)
      }

      return itemGroups
    }

  }
}
</script>

<style lang="scss">
@keyframes pulse {
  // 0% {
  //   background-color: #FFF3CD;
  // }

  // 50% {
  //   background-color: #FFE082;
  // }

  // 100% {
  //   background-color: #FFF3CD;
  // }

  // 0% {
  //   background-color: #f8f9fa; /* Lightest grey */
  // }

  // 50% {
  //   background-color: #e9ecef; /* Slightly darker grey */
  // }

  // 100% {
  //   background-color: #f8f9fa; /* Lightest grey */
  // }
  0% {
    background-color: #f8d7da; /* Light red (starting point) */
  }

  50% {
    background-color: #f5c6cb; /* Slightly darker red for contrast */
  }

  100% {
    background-color: #f8d7da; /* Light red (ending point) */
  }
}

.doctor {
  font-weight: bold;
  background-color: #fff;
  color: #274e13ff;
  padding: 3px;
  border: 3px solid #274e13ff;
  border-radius: 0.5em;
}

.insurance {
  font-weight: bold;
  background-color: #fff;
  color: #3c78d8ff;
  padding: 3px;
  border: 3px solid #3c78d8ff;
  border-radius: 0.5em;
}

.nurse,
.paramedic {
  font-weight: bold;
  background-color: #fff;
  color: #1d800eff;
  padding: 3px;
  border: 3px solid #1d800eff;
  border-radius: 0.5em;
}

.publicHealth {
  font-weight: bold;
  background-color: #fff;
  color: #a4c964ff;
  padding: 3px;
  border: 3px solid #a4c964ff;
  border-radius: 0.5em;
}

.attend-class {
  background-color: #1d800eff;
  color: #fff !important;
  margin-right: -10px;
  margin-left: -10px;
}

.observe-class {
  background-color: #F9D83F !important;
  color: #fff !important;
  margin-right: -10px;
  margin-left: -10px;
}

.diag-class {
  background-color: #fcf5c7;

  margin-right: -10px;
  margin-left: -10px;
}


.patient-summary-table {

  .vgt-table.bordered td,
  .vgt-table.bordered th {
    border: 1px solid #acacac;
  }

  .row-attended {
    border: 2px solid #1d800eff !important;
    background-color: #DBE4A9 !important;
    /* Light green background for visibility */
  }

  .row-observation {
    border: 2px solid #a4c964ff !important;
    background-color: #fffbea !important;
    /* Light yellow background for visibility */
  }

  .row-default {
    border: 1px solid #acacac !important;
    background-color: white !important;
  }



  .temporary-row {
    //background-color: #FFCDD2;
    animation: pulse 2s infinite;

  }

  .reject-tab {
    background-color: #dc0000ff !important;
  }

  .med-tab {
    background-color: #274e13ff !important;
  }

  .note-tab {
    background-color: #FFC300 !important;
  }

  .paymentstatus-row {
    padding: 5px;
    border-radius: 0.5em;
  }

  .resident-row {
    background-color: #A0A0A0;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .foreigner-row {
    background-color: #A0A0A0;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .insurance-row {
    background-color: #1565C0;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .hospital-row {

    background-color: #1976D2;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .slinky-row {

    background-color: #D32F2F;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .aesthetic-row {

    background-color: #FBC02D;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .pier-row {

    background-color: #388E3C;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

  .central-row {

    background-color: #7B1FA2;
    color: #fff;
    padding: 5px;
    border-radius: 0.5em;
  }

}
</style>