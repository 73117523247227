<template>
  <div class="row mt-2">
    <div class="row mx-4">
    <!-- <div v-if="$route.name == 'Billing Summary' || $route.name == 'Insurance' " class="row mx-4"> -->
      <div class="d-flex mx-auto gap-x-2 mt-2 mx-2">
        <div class="span3 text-center mx-2">
          <MazSelect
            v-model="search_date_type"
            placeholder="select date filter type"
            @change="handleFilterDateSearchType"
            :options="[
              { label: 'Search by day', value: 'by-day' },
              { label: 'Search by range', value: 'by-range' },
            ]"
          />
        </div>
        <div v-if="search_date_type === 'by-day'" class="span3 text-center d-flex mx-3">
          <button class="btn btn-primary mx-1" @click.prevent="triggerSearchDate(-1)"><i class="fa fa-angle-left" aria-hidden="true"></i></button>
          <MazPicker
            v-model="search_date_value"
            no-time
            format="DD/MM/YYYY"
            formatted="DD/MM/YYYY"
            placeholder="Period Date"
          />
          <button class="btn btn-primary mx-1" @click.prevent="triggerSearchDate(1)"><i class="fa fa-angle-right" aria-hidden="true"></i></button>
        </div>
        <div v-if="search_date_type === 'by-range'" class="span3 text-center">
          <MazPicker
            v-model="filterDate"
            no-time
            format="DD/MM/YYYY"
            formatted="DD/MM/YYYY"
            placeholder="Period Date Range"
            range
          />
        </div>
        <div class="span3 text-center d-flex mx-3">
          <button class="btn btn-primary mx-auto ml-2 px-4" @click.prevent="triggerInitData"><h5>Search</h5></button>
        </div>
      </div>
    </div>
    <!-- <template v-if="$route.name != 'Billing Summary' && $route.name != 'Insurance' ">
      <div class="col-md-2 col-sm-12 text-center">
        <MazPicker v-model="from" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" placeholder="Start Date" />
      </div>
      <div class="col-md-2 col-sm-12 text-center">
        <MazPicker v-model="to" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" placeholder="End Date" />
      </div>
      <div class="col-md-8 col-sm-12">
        <b class="mr-2">Period: </b>
        <button class="btn mr-2 shadow" v-for="(item, index) in periods" :key="`period${index}`" @click="handlePeriod(item)"
          :class="{ 'btn-primary': period.name === item.name }">
          <div><b>{{ item.name }}</b></div>
        </button>
      </div>
    </template> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ['value'],
  data() {
    return {
      search_date_type: 'by-day',
      search_date_value: this.$moment(new Date(), 'DD/MM/YYYY').toDate(),
      filterDate: {
        start: this.$moment(new Date(), 'DD/MM/YYYY').toDate(),
        end: this.$moment(new Date(), 'DD/MM/YYYY').toDate()
      },
      from: this.$moment().add(-1, 'day').format('DD/MM/YYYY'),
      to: this.$moment().format('DD/MM/YYYY'),
      periods: [
        { name: 'Day', from: this.$moment().add(-1, 'day').format('DD/MM/YYYY') , to: this.$moment().format('DD/MM/YYYY'), income: 'Daily', total: 'TODAY\'S' },
        { name: 'Week', from: this.$moment().add(-1, 'week').format('DD/MM/YYYY') , to: this.$moment().format('DD/MM/YYYY'), income: 'Weekly', total: 'THIS WEEK\'S'  },
        { name: 'Month', from: this.$moment().add(-1, 'month').format('DD/MM/YYYY') , to: this.$moment().format('DD/MM/YYYY'), income: 'Monthly', total: 'THIS MONTH\'S'  },
        { name: 'Year', from: this.$moment().add(-1, 'year').format('DD/MM/YYYY') , to: this.$moment().format('DD/MM/YYYY'), income: 'Yearly', total: 'THIS YEAR\'S'  },
      ],
      period: { name: 'Day', from: this.$moment().add(-1, 'day').format('DD/MM/YYYY'), to: this.$moment().format('DD/MM/YYYY'), income: 'Daily', total: 'TODAY\'S' },
    }
  },
  mounted(){
    if(this.value){
      this.from = this.value.from
      this.to = this.value.to
    }
  },
  methods: {
    handlePeriod(item) {
      this.period = item
      this.from = item.from
      this.to = item.to
    },
    handleFilterDateSearchType(type) {
      this.search_date_type = type
    },
    triggerSearchDate(step) {    
      let currentDateSearch = this.$moment(this.search_date_value, 'DD/MM/YYYY')
      this.search_date_value = moment(currentDateSearch.clone().add(step, 'days').toDate()).format("DD/MM/YYYY");
      // payloadSearch.from = this.search_date_value
      // payloadSearch.to = this.search_date_value
      // payloadSearch.period = this.period
    },
    triggerInitData() {      
      const payloadSearch = {
        from: '',
        to: '',
        period: ''
      }
      if (this.search_date_type == 'by-day') {
        payloadSearch.from = this.search_date_value
        payloadSearch.to = this.search_date_value
        payloadSearch.period = this.period
      }
      if (this.search_date_type == 'by-range') {
        payloadSearch.from = this.filterDate.start
        payloadSearch.to = this.filterDate.end,
        payloadSearch.period = this.period
      }
      
      this.$emit('input', payloadSearch)
    }
  },
  watch: {
    from: {
      handler() {
        this.$emit('input', {
          from: this.from,
          to: this.to,
          period: this.period
        })
      }
    },
    to: {
      handler() {
        this.$emit('input', {
          from: this.from,
          to: this.to,
          period: this.period
        })
      }
    },
  }
}
</script>