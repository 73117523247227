<template>
  <div>
    <section class="p-4 sheet padding-10mm receipt-form" v-if="receipt && invoice">
      <div class="d-flex">
        <img v-if="$store.state.branch == 3" src="/images/wa-logo.png" class="img-fluid mx-auto" style="width: 94px;" />
        <img v-else src="/images/worldmed-logo-2.jpg" class="img-fluid mx-auto" style="width: 94px;" />
      </div>
      <div class="mt-2 d-flex justify-content-between">
        <div>
          <div>
            <strong class="text-primary">Patient Name:</strong>
            <p class="text-red-700">{{ invoice.patient_name }}</p>
          </div>
          <div class="mt-2">
            <strong class="text-primary">Receipt to:</strong>
            <p class="text-red-700">{{ invoice.billed_to }}</p>
          </div>
        </div>
        <div style="width: 40%;">
          <div class="d-flex">
            <strong class="text-primary w-25">Receipt #:</strong>
            <p class="text-red-700 ml-5">{{ receipt.payment_id }}</p>
          </div>
          <div class="d-flex">
            <strong class="text-primary w-25">Invoice #:</strong>
            <p class="text-red-700 ml-5">{{ receipt.invoice_id }}</p>
          </div>
          <div class="d-flex">
            <strong class="text-primary w-25">HN :</strong>
            <p class="text-red-700 ml-5">{{ receipt.HN }}</p>
          </div>
          <div class="d-flex">
            <strong class="text-primary w-25">Visit Date :</strong>
            <p class="text-red-700 ml-5">{{ receipt.VN.slice(11, 13) + '/' + receipt.VN.slice(13, 15) + '/' + receipt.VN.slice(15, 19) }}</p>
          </div>
          <!-- <div class="d-flex">
            <strong class="w-25">Date Issued</strong>
            <p class="text-red-700 ml-5">{{ receipt.issue_date.toDate() | moment('DD/MM/YYYY HH:mm') }}</p>
          </div>
          <div class="d-flex">
            <strong class="w-25">Practitioner</strong>
            <p class="text-red-700 ml-5">{{ receipt.practitioner }}</p>
          </div> -->
        </div>
      </div>
      <short-product-table :data="invoice" v-if="$route.query.mode === 'short'" />
      <HidePriceProductTable :data="invoice" v-else-if="$route.query.mode === 'hide'" />
      <product-table :data="invoice" v-else />
      <div class="d-flex justify-content-between" style="padding-top: 50px">
        <div class="d-flex flex-column">
          <strong class="mt-2">Notes: </strong>
          <p>{{ receipt.note }}</p>
        </div>
        <div class="d-flex">
          <div>
            <div class="d-flex py-1 px-2 justify-content-end">
              <p class="my-auto">Total Amount</p>
            </div>
            <div class="d-flex py-1 px-2 justify-content-end" v-if="totalDiscount > 0">
              <p class="my-auto">Discount</p>
            </div>
            <!-- <div class="d-flex py-1 px-2 justify-content-end">
              <p class="my-auto">Payment Applied</p>
            </div> -->
            <div class="d-flex py-1 px-2 justify-content-end" v-if="totalPaid > 0">
              <p class="my-auto">Paid Amount</p>
            </div>
            <div class="d-flex py-1 px-2 justify-content-end">
              <p class="my-auto">OutStanding Balance</p>
            </div>
          </div>
          <div style="width: 186px;">
            <div class="border d-flex py-1 px-2 justify-content-end">
              <p class="my-auto">฿{{ totalAmount.toLocaleString(undefined) }}</p>
            </div>
            <div class="border d-flex py-1 px-2 justify-content-end" v-if="totalDiscount > 0">
              <p class="my-auto">฿{{ Math.floor(totalDiscount).toLocaleString(undefined) }}</p>
            </div>
            <!-- <div class="border d-flex py-1 px-2 justify-content-end">
              <p class="my-auto">฿{{ paymentApplied.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</p>
            </div> -->
            <div class="border d-flex py-1 px-2 justify-content-end border-top-0" v-if="totalPaid > 0">
              <p class="my-auto">฿{{ totalPaid.toLocaleString(undefined) }}</p>
            </div>
            <div class="border d-flex py-1 px-2 justify-content-end border-top-0">
              <p class="my-auto">฿{{ (totalAmount - Math.floor(totalDiscount) - totalPaid).toLocaleString(undefined) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex mt-5" style="padding-top: 50px">
        <div class="mx-auto">
          <p class="border-bottom border-secondary" style="width: 168px;"></p>
          <p class="text-center" style="margin-top: -10px;">Provider's Signature</p>
        </div>
      </div>
      <div class="mt-4 text-center text-secondary" style="border-bottom: 1px solid #d1d1d1;padding-top: 50px">
        <p>WORLDMED CENTER</p>
        <p style="margin-top: -12px;">125/131-133, M.7 Phi Phi Island, T.Aonang, A.MuangKrabi, Krabi, 81210</p>
        <p style="margin-top: -12px;">Thailand 095-424-0444 & 075-601-444 www.worldmed.center</p>
      </div>
      <!-- <div class="mt-4">
        <strong>Patient Detail:</strong>
      </div> -->
      <div style="padding-top: 10px">
        <div v-for="(payment, index) in payments" :key="payment.issue_date || index">
          <strong>{{ payment.issue_date.toDate() | moment('DD/MM/YYYY HH:mm') }}</strong>
          <p v-if="payment.cash > 0">Cash: ฿{{ payment.cash.toLocaleString(undefined) }}</p>
          <p v-if="payment.credit > 0">Credit/Debit: ฿{{ payment.credit.toLocaleString(undefined) }} {{ `(extra charge
            ${payment.extra_charge*100}%`}}: ฿{{ (payment.credit * payment.extra_charge).toLocaleString(undefined) }})</p>
          <p v-if="payment.insurance > 0">Insurance: ฿{{ payment.insurance.toLocaleString(undefined) }}</p>
          <p v-if="payment.internet_banking > 0">InternetBanking: ฿{{ payment.internet_banking.toLocaleString(undefined)
            }}</p>
          <p v-if="payment.other > 0">Other: ฿{{ payment.other.toLocaleString(undefined) }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HidePriceProductTable from '../../components/Table/HidePriceProductTable.vue';
import { db } from '../../db'
export default {
  data() {
    return {
      receipt: null,
      invoice: null,
      payments: []
    }
  },
  async mounted() {
    document.body.className = "A4";

    let paymentData = await db.collection('Payment').doc(this.$route.params.receiptId).get()
    
    this.receipt = {
      ...paymentData.data()
    }
    
   
  
    let invoiceData = await db.collection('Invoice').doc(this.receipt.invoice_id).get()
    // console.log(invoiceData.data())
    this.invoice = {
      ...invoiceData.data()
    }

    let allPaymentData = await db.collection('Payment').where('invoice_id', '==', this.invoice.invoice_id).get()
    for (let item of allPaymentData.docs) {
      this.payments.push({
        ...item.data()
      })
    }

    

  },
  computed: {
    totalDiscount() {
      let sum_product = _.sum(this.invoice.product_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))
      let sum_billable_items = _.sum(this.invoice.billable_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))

      return sum_product + sum_billable_items
    },
    totalAmount() {
      let invoice = this.invoice
      return _.sum(invoice.product_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * (Number(item.price) )
        } else {
          return ''
        }
      })) + _.sum(invoice.billable_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * (Number(item.price) )
        } else {
          return ''
        }
      }))

    },
    totalPaid() {
      let sum = 0
      for (let item of this.payments) {
        sum += Number(item.cash) + Number(item.credit) + Number(item.insurance) + Number(item.internet_banking) + Number(item.other)
      }
      return sum
      // return Number(this.receipt.cash) + Number(this.receipt.credit) + Number(this.receipt.insurance) + Number(this.receipt.internet_banking) + Number(this.receipt.other)
    },
    paymentApplied() {
      return this.invoice.payment_applied
    }
  }
}
</script>

<style lang="css" scoped>
.receipt-form {
  font-size: 13px;
}

.receipt-form-table {
  font-size: 11px;
}

.table th,
.table td {
  padding: .3rem;
}

@page {
  size: A4;
  margin: 5mm 0mm;
}

.sheet {
  overflow: visible;
  height: auto !important;
}
</style>
