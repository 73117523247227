export default {
  methods: {
    isAllow(permission_name, level) {
      for (let [roleId, role] of Object.entries(this.$store.getters.user.roles)) {
        if (role[permission_name] >= level) return true
      }
    }
  },
  computed: {
    permission() {
      return this.$store.getters.permission
    }
  },
  watch: {
    permission: {
      handler() {
        
        this.$mount()
        
      },
      deep: true
    }
  }
}