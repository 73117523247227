<template>
    <div>
        <h5 class="text-primary mt-2">Doctor List</h5>
        <vue-good-table :columns="columns" :rows="doctors" :sort-options="{ enabled: false, }" style="width:25%;"
            @on-row-click="showData">
            <div slot="table-actions">
                <button class="btn btn-primary btn-block" @click="showData()">
                    <i class="fas fa-plus"></i> Add
                </button>
            </div>
        </vue-good-table>
        <b-modal hide-footer size="sm" :title="'Add Doctor'" id="register-modal">            
            <div class="row">
                <div class="col-12">
                    <MazInput :placeholder="'Name'" v-model="doctor_list.value" />
                </div>
                <div class="col-12 mt-2">
                    <button class="btn btn-primary btn-block" @click="saveData">
                        <i class="fas fa-save"></i> Save
                    </button>
                </div>
                <div class="col-12 mt-2" v-if="isEdit">
                    <button class="btn btn-danger btn-block" @click="deleteData">
                        <i class="fas fa-trash"></i> Delete
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

import { db } from "../../db";
export default {
    firestore() {
        return {
            doctors: db.collection("doctor_lists"),
        }
    },
    data() {
        return {
            doctors: [],
            doctor_list: { label: "", value: "" },
            columns: [
                { label: "Name", field: "value" }
            ],
            isEdit: false,
        }
    },
    methods: {

        showData(params) {
            if (params) {
                this.doctor_list = {
                    label: params.row.value,
                    value: params.row.value
                }
                this.isEdit = true
            }
            else {
                this.doctor_list = { label: "", value: "" }
                this.isEdit = false
            }
            this.$bvModal.show("register-modal")
        },
        async saveData() {
            let loader = this.$loading.show({})
            
            try {
                this.doctor_list.label = this.doctor_list.value
                let list = { ...this.doctor_list }
                await db
                    .collection("doctor_lists")
                    .doc(this.doctor_list.value)
                    .set(list)
                loader.hide()
                this.$alert("success", null, "success")
                this.$bvModal.hide("register-modal")
            } catch (error) {
                this.$alert(`error ${error}`, null, "error")
                loader.hide()
            }
        },
        deleteData() {
            
            this.$confirm("do you want to delete this?").then(
                async () => {
                    let loader = this.$loading.show({})
                    
                    await db.collection("doctor_lists").doc(this.doctor_list.label).delete()
                    
                    loader.hide()
                    this.$bvModal.hide("register-modal")

                    this.$alert(`doctor was deleted.`, null, "success")
                }
            )
        },
    },
}
</script>
